.imagenes {
    width: 100%;
    height: 100%;
}

.grid {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 25rem;
    grid-gap: 1rem;
    justify-content: space-between;
}

.grid>.imgContainer:nth-child(odd),
.imgGalleriaVisible:nth-child(odd) {
    transform: rotate(180deg);
}

div.p-galleria-mask.p-galleria-visible.p-component-overlay.p-component-overlay-enter>div>button {
    z-index: 1000;
}

.btns {
    flex-direction: row;
}

.btns Button {
    background-color: black;
    margin-left: 0.625rem;
}

@media (max-width: 689px) {
    .btns {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .btns Button {
        width: 100%;
        margin-bottom: 1rem;
        margin-left: 0;
    }
}

@media screen and (min-width: 450px) {

    .grid {
        display: grid !important;
        grid-template-columns: 46% 46%;
        grid-auto-rows: 15rem;
        grid-gap: 1.5rem;
    }

}

@media screen and (min-width: 688px) {

    .grid {
        grid-template-columns: 30% 30% 30%;
        grid-auto-rows: 18rem;
    }

}

@media screen and (min-width: 992px) {

    .grid {
        grid-template-columns: 22% 22% 22% 22%;
        grid-auto-rows: 20rem;
    }
}

@media screen and (min-width: 1312px) {}