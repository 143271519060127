.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.0625rem solid var(--gray-color);
    margin-bottom: 1.5rem;
    padding: 0.5rem 0;
}

.input-edit-header {
    width: 100%;
    border: none;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--dark-color);
    padding: 0 0.9375rem;
    height: 3rem;
}

.title {
    margin: 1rem 0;
    line-height: 2.5rem;
    font-size: 1.6rem;
}

.input-edit-header:hover {
    border-bottom: 0.0625rem solid rgb(228, 228, 228);
    box-shadow: 0 0.9375rem 0.625rem -0.9375rem var(--light-shadow-color);
}

.input-edit-header:focus {
    outline: none;
    border-bottom: 0.0625rem solid rgb(228, 228, 228);
    box-shadow: 0 0.9375rem 0.625rem -0.9375rem var(--light-shadow-color);
}

.advert {
    margin-left: 0.5rem;
    display: none;
    position: relative;
    top: -10px;
}

.advert h5 {
    font-size: 1rem;
    color: var(--light-color);
    background-color: var(--grayish-blue);
    height: 3rem;
    width: 9rem;
    border-radius: 0.625rem;
    line-height: 3rem;
    text-align: center;
}

.previous {
    rotate: 180deg;
    position: relative;
    color: var(--grayish-blue);
    left: -0.3125rem;
    top: 1.875rem;
    width: 0.625rem;
}

.visible {
    display: flex;
    align-items: center;
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {

    .header {
        margin-bottom: 1rem;
        padding: 1rem 0;
    }

    .title {
        font-size: 2rem;
    }

    .input-edit-header {
        font-size: 2rem;
    }
}