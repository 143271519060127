.modalSwitch {
    display: flex;
    justify-content: space-between;
    margin: 0.9375rem 0;
}

.spacePasswords {
    display: none;
}

.modalBody {
    margin-top: 1rem;
}

.inputModal {
    width: 18rem !important;
}

.inputUsers {
    width: 100% !important;
    border: 0.0625rem solid var(--gray-intermediate-color) !important;
}

.modalBackgroundChangeBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 6.25rem;
    color: var(--dark-color);
}

.modalTitle {
    color: var(--dark-color);
}

.fancy_file {
    display: none;
}

.fancy_file_label {
    display: flex;
    width: 100%;
    border: 0.0625rem solid var(--gray-light-color);
    border-radius: 0.625rem;
    height: 3.125rem;
    align-items: center;
    padding-left: 0.625rem;
    margin-bottom: 0;
}

.fancy_file_name {
    width: 90%;
    font-weight: normal;
}

.fancy_file_name span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.fancy_file_button {
    background-color: var(--tertiary-color);
    border: 0.0625rem solid var(--tertiary-color);
    border-radius: 0.625rem;
    color: var(--light-color);
    white-space: nowrap;
    width: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.1875rem;
    cursor: pointer;
}

.fancy_file_name,
.fancy_file_button {
    padding: 0.3125rem 0.625rem;
}