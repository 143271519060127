.labelUsers {
    font-weight: 200 !important;
    margin: 0.625rem 0 !important;
}

.selectUsers {
    width: 100%;
    border: 0.0625rem solid #6969697e !important;
    height: 1.875rem;
}

.selectUsers:focus {
    border: 0.125rem solid var(--gray-shadow-color) !important;
}

.containerToggle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

input::placeholder {
    color: var(--gray-color);
    font-size: small;
}

.btnDark {
    padding: 0.375rem 0.75rem;
    background-color: #000000;
}

.errorMessage {
    color: var(--red-color);
    font-size: 0.75rem;
    margin: 0.5rem 0 0;
}

.containerToggle Button:hover {
    background-color: var(--gray-color);
}

/* version Tablet 689px hasta 991px */
@media (max-width: 689px) {

    .containerToggle {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .containerToggle Button {
        width: 100%;
        margin-bottom: 1rem;
        margin-left: 0;
        margin-left: 0 !important;
    }

}