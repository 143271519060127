.bodyPaper {
    background-color: var(--light-color);
    width: 80%;
    height: 100%;
    margin: 1rem;
    padding: 2rem;
    -webkit-box-shadow: 0 0 0.938rem -0.313rem var(--dark-shadow-color);
    -moz-box-shadow: 0 0 0.938rem -0.313rem var(--dark-shadow-color);
    box-shadow: 0 0 0.938rem -0.313rem var(--dark-shadow-color);
}

.containerPaper {
    padding: 0.0625rem;
}

.container-toggle {
    width: 100%;
    display: flex;
    align-items: center;
}

.buttonsApps {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.buttonsApps button:hover {
    background-color: var(--gray-color) !important;
}

.slider-label {
    font-size: 1.125rem;
    font-weight: 600 !important;
    margin: 0 0.313rem 0.313rem 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.6 rem;
    align-items: center;
    margin-bottom: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-color);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 40%;
}

.slider:before {
    position: absolute;
    content: "";
    height: 0.9rem;
    width: 0.9rem;
    left: 0.13rem;
    bottom: 0.3rem;
    background-color: var(--light-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider.round:before {
    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--dark-color);
}

input:focus+.slider {
    box-shadow: 0 0 0.0625rem var(--dark-color);
}

input:checked+.slider:before {
    -webkit-transform: translateX(1.625rem);
    -ms-transform: translateX(1.625rem);
    transform: translateX(1.625rem);
}

.inputs-labels {
    margin-left: 0.4375rem;
    border-color: var(--dark-light-color);
}

.visible {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btnIRight {
    transform: rotate(45deg);
    transition: all .3s ease-in-out;
}

.btnILeft {
    transform: rotate(0deg);
    transition: all .3s ease-in-out;
}