.contenButtons {
    display: flex;
    align-items: center;
    margin: 2rem 0 2rem 0;
}

.contenButtons Button {
    background-color: var(--dark-color) !important;
    border-color: var(--dark-color) !important;
    margin-right: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenButtons Button:hover {
    background-color: var(--gray-color) !important;
}


.contenButtons Button i {
    margin-right: 0.5rem;

}