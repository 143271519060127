.headerManager {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;
}

.button {
    background-color: var(--dark-color) !important;
}

.button:hover {
    background-color: var(--gray-shadow-color) !important;
}

@media screen and (max-width: 689px) {
    .button {
        width: 100%;
    }
}