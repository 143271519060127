.main-footer {
    height: 2rem !important;
    background-color: var(--dark-color);
    color: white;
    text-align: center;
    padding: 0.0625rem;
}

.rights {
    font-size: 0.75rem;
}