.GridDashboard {
  margin: 0 auto;
  text-align: center;
}

.headerDash {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  grid-gap: 1rem;
  width: 100%;
}

.contents {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  height: 100%;
}

.lineBar {
  display: block;
}

.contents2 {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;
}

.p-button {
  background: var(--dark-color) !important;
  border: var(--dark-color) !important;
}

.red {
  background-color: var(--red-color) !important;
}

.headerDash p {
  display: flex;
  justify-content: flex-start;
}

.bodyDash {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 30rem;
  width: 100%;
}

.containerBoxChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 14rem; */
  min-height: 20rem !important;
}

.spinner {
  position: relative;
  margin: 0 auto;
  /* width: 100px; */
  height: 100px;
  /* display: inline-block; */
  text-align: center;
}

button {
  height: 2.5rem;
  color: var(--light-color) !important;
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {

  .GridDashboard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    height: 56rem;
  }

  .headerDash {
    display: grid;
    grid-template-columns: 50% 49%;
    grid-template-rows: 100%;
    grid-gap: 1rem;
    width: 90%;
    margin: auto;
    justify-content: center;
    margin-bottom: 2.1rem;
  }

  .contents {
    bottom: 29.0625rem;
  }

  .contents2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-gap: 1rem;
  }

  .info {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0.65rem;

    width: 100%;
    justify-content: center;
    grid-template-rows: 15.5% 15.5% 15.5% 15.5%;
    padding-bottom: 15rem;
  }

  .bodyDash {
    display: block;
    margin-top: 3rem;
    margin: auto;
    width: 100%;
  }

  .bodyDashMap {
    position: relative;
    bottom: 29rem;
  }

  .containerBoxChart {
    grid-template-rows: 23.1% 23.1% 23.1% 23.1%;
    margin-bottom: 0;
    /* max-width: 14rem; */
  }

  .contents {
    position: relative;
    top: -29rem;
  }

  .spinner {
    position: relative;
    bottom: 23%;
  }

  .navLinkLastLocation{
    position: absolute;
    top: -3.5rem;
    right: 0.5rem;
  }
}

/* version Desktop 992px hasta 1311px */
@media (min-width: 992px) {
  .contents2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-gap: 1rem;
  }

  .bodyDash {
    margin-top: 0;
  }

  .headerDash p {
    font-size: 0.9rem;
  }

  .spinner {
    position: relative;
    bottom: 21%;
  }

}

/* version Full-Desktop 992px hasta 1311px */
@media (min-width: 1312px) {
  .contents2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 35% 65%;
    grid-gap: 1rem;
  }

  .containerBoxChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-width: 14rem; */
  }

}