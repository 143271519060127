.main_container {
    background-color: var(--light-color);
    min-height: 100vh;
    padding: 2rem;
}

.spaceCharts {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: 100%;
    grid-gap: 1.5rem;
    width: 91%;
    margin: auto;
}

.spaceSearch {
    width: 91%;
    height: 37.5rem;
    margin: 5rem auto;
}

.safeW {
    width: 91%;
    height: 37.5rem;
    margin: 0 auto;
}

.top3_container {
    width: 48%;
    margin-right: 1rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.top10_container {
    width: 48%;
    margin-left: 1rem !important;
    height: 30rem !important;
}

.dataTL {
    width: 91%;
    margin: 5rem auto 0 auto;
}

.tops_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}


@media (max-width: 780px) {
    .spaceCharts {
        grid-template-columns: 100%;
        grid-template-rows: 33% 33% 33%;
        grid-gap: 1rem;
        width: 100%;
    }

    .spaceSearch {
        height: auto;
    }

    .tops_container {
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-top: 2rem;
        width: 95%;
    }

    .top3_container {
        margin-right: 0 !important;
        width: 95%;
    }

    .top10_container {
        width: 95%;
        margin-left: 0 !important;
    }
}