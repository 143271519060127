.containerBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--light-color);
    margin: 0;
    border-radius: 0.625rem;
    -webkit-box-shadow: 0 0.25rem 0.625rem 0 var(--light-shadow-color);
    -moz-box-shadow: 0 0.25rem 0.625rem 0 var(--light-shadow-color);
    box-shadow: 0 0.25rem 0.625rem 0 var(--light-shadow-color);
    margin: 0.5rem 0;
}

.headerBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0.9375rem 1.25rem 0 1.25rem;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.headerBox .p-avatar.p-component.p-avatar-circle.p-avatar-lg.mr-2 {
    padding: 1rem;
}

.content-avatar-title {
    display: flex;
    align-items: center;
}

.chartjs-render-monitor {
    object-fit: contain;
}

.headerBox a.btn.btn-dark {
    font-size: 0.9rem;
    padding: 0.6rem;
}

.headerBox a.btn.btn-dark:hover {
    background-color: var(--gray-color);
}

.bodyBox {
    padding: 0 0.4rem 2.125rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.headerBox h5 {
    margin: 0;
    font-size: 1.2rem
}

@media (min-width: 689px) {

    .bodyBox {
        padding: 0 1.25rem 3.125rem 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .containerBox {
        height: 27rem;
    }

    .headerBox h5 {
        margin: 0;
        font-size: 1.4rem
    }

    .bodyBox h3 {
        font-size: 1.2rem;
    }

    .headerBox a.btn.btn-dark {
        font-size: 0.9rem;
        padding: 0.4rem 0.6rem;
    }

}

@media (min-width: 992px) {
    .bodyBox {
        padding: 0 0.5rem 1.125rem 0.5rem;
    }

    .containerBox {
        height: 27rem;
    }

}

@media (min-width: 1312px) {
    .containerBox {
        height: 27rem;
    }
}