.slider-demo h5 {
    margin-left: 0.9375rem;
}

.slider-demo span {
    margin-right: 0.9375rem;
    font-weight: 630;
}

.p-slider .p-slider-range {
    background: var(--dark-color) !important;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: var(--dark-color) !important;
    border-color: var(--dark-color) !important;
}

.p-slider .p-slider-handle {
    border: 0.125rem solid var(--dark-color) !important;
}

.p-slider .p-slider-handle:focus {
    box-shadow: 0 0 0 0.1rem rgba(16, 16, 16, 0.367) !important;
}

.slider-demo .p-slider-horizontal,
.slider-demo .p-inputtext {
    width: 14rem;
}

.slider-demo .p-slider-vertical {
    height: 14rem;
}

.slider-demo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.p-inputtext {
    border-bottom: none !important;
}

.multiselect-demo .p-multiselect {
    min-width: 15rem;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
    padding: .25rem .5rem;
    border-radius: 0.1875rem;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--dark-color);
    color: var(--primary-color-text);
}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
    width: 1.0625rem;
}


.dropdown-demo .p-dropdown {
    width: 14rem;
}

.dropdown-demo .country-item-value img.flag {
    width: 1.0625rem;
}